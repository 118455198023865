import React, {useEffect, useRef} from 'react';

const PriceMinimum = ({...props}) => {
    const onlinePrice = useRef(null);

    useEffect(() => {
        if (onlinePrice.current !== null) {
            onlinePrice.current.insertAdjacentHTML('beforebegin', '<!--noindex-->');
            onlinePrice.current.insertAdjacentHTML('afterend', '<!--/noindex-->');
        }
    }, []);

    const {el, type = ''} = props;
    const {minimumPriceColor = '', minimumPrice = 0, minimumPriceText = ''} = el;

    if (minimumPrice === 0) {
        return null
    }

    if (type === 'cardModel') {
        return <div className={`cardModel__discount`} style={{'backgroundColor': minimumPriceColor}}>
            {minimumPrice.toLocaleString('ru')}&nbsp;₽ {minimumPriceText}
        </div>
    } else {
        return <div className={`onlinePrice`} ref={onlinePrice}>
            <p className={`onlinePrice__price`} style={{'backgroundColor': minimumPriceColor}}>
                {minimumPrice.toLocaleString('ru')}&nbsp;₽
            </p>
            <p className={`onlinePrice__text`} style={{'color': minimumPriceColor}}>
                {minimumPriceText}
            </p>
        </div>
    }
}

export default PriceMinimum;
